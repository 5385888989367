module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-124502642-2","anonymize":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Halbe Henn","short_name":"Halbe Henn","start_url":"/","background_color":"#000000","theme_color":"#fb5858","display":"standalone","icon":"static/drumstick-bite.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e7e56f7d2279dbe7e7e33d9cd1d433fc"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
